/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { useNavigationLinks } from 'hooks';
import MenuItem from './MenuItem';
import './SideBar.css';

function SideBar({ menuItems, isVisible, onClickToggle }) {
  const { getURL } = useNavigationLinks();

  useEffect(() => {    
    const script = document.createElement('script');
    script.id = "ze-snippet"
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=a0f677bb-2590-4e35-9137-5e0757f552ab";
    script.async = true;
    script.onload = function () {
      window.zESettings = {
        webWidget: {
          offset: { horizontal: '10px', vertical: '70px' },
          helpCenter: {
            filter: {
              category: process.env.REACT_APP_ZENDESK_CATEGORY
              //                            section: '@ViewBag.Zendesk'
            }
          }
        }
      };
    };

    const existingScript = document.getElementById("ze-snippet");
    if (!existingScript) {
      document.body.appendChild(script);
    }

    if (window.zE) {
      window.zE.apply(null, ['webWidget', 'show']);
    }
    

  },[])

  return (
    <>
      <div id="sidebar" className="sidebar-wrapper overflow-auto d-print-none">
        {menuItems.map((item) => (
          <MenuItem
            key={item.key}
            label={item.label}
            icon={item.icon}
            target={getURL(item.key)}
            subItems={item.subItems}
          />
        ))}
         <div className="nav flex-column border-top">
        <a className="nav-link" href={process.env.REACT_APP_HELP_URL} target="_blank" rel="noreferrer noopener">
          {" "}
          <FontAwesomeIcon
            className="mr-1 text-secondary mt-3"
            icon={faQuestionCircle}
            size="sm"
          />{" "}
          Help
        </a>
      </div>
      </div>
     
      <button
        type="button"
        className="btn d-print-none sidebar-toggle-btn"
        style={{ left: isVisible ? '-50px' : '0px', bottom: '0px' }}
        onClick={onClickToggle}
      >
        <FontAwesomeIcon className="d-print-none" icon={isVisible ? faAngleDoubleLeft : faAngleDoubleRight} />
      </button>
    </>
  );
}
SideBar.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClickToggle: PropTypes.func.isRequired,
};

export default SideBar;
