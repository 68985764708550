import React, { memo, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DashboardButton from './DashboardButton';


function RoleDashboard({ postDashboardOptions }) {
  const { role, totalCount, employeePostActions } = postDashboardOptions;

  return (
    <Card className="mb-4">
      <CardHeader>
        <div>
            <Accordion activeIndex={null} >
                <AccordionTab header = {`${role} (${totalCount})`}>
                  <CardBody>
                    <div>
                      {employeePostActions?.map((postOpt) => (
                        <DashboardButton dashboardOptions={postOpt} key={uuid()} />
                      ))}
                    </div>
                  </CardBody>
                </AccordionTab>                
            </Accordion>
        </div>        
      </CardHeader>
    </Card>
  );
}

RoleDashboard.propTypes = {
  postDashboardOptions: PropTypes.object,
};

RoleDashboard.defaultProps = {
  postDashboardOptions: {},
};

export default memo(RoleDashboard);
