/* eslint-disable react/jsx-key */
import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-unresolved
import './Dashboard.css';

function DashboardButton({ dashboardOptions }) {
  return (
    <div>
      <h5>{dashboardOptions.postName}</h5>
      <div className="row">
        {dashboardOptions.employeeActions?.map((action) => (
          <div className="col-4" key={uuid()}>
            <div className="media shadow-sm rounded-lg p-3 dashboardCard">
              <div className="media-body">
                <h5 className="mt-0 mb-1 font-weight-bold text-primary text-uppercase">{`${action.nominationStatus} (${action.totalCount})`}</h5>
                {action.cycles?.map((cycle) => (
                  <div className="row" key={uuid()}>
                    <div className="col-12">
                      <Link to={cycle.navigateTo ?? '/Home'}>
                        <span>{cycle?.awardProcessTypeName && `(${cycle.awardProcessTypeName})`}</span>
                        <span>{cycle?.cycleName && ` - ${cycle.cycleName} (${cycle.totalCount})`}</span>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <FontAwesomeIcon
                className="ml-3 text-light‑gray align-self-start"
                icon={['far', action.icon]}
                size="2x"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

DashboardButton.propTypes = {
  dashboardOptions: PropTypes.object,
};

DashboardButton.defaultProps = {
  dashboardOptions: {},
};

export default memo(DashboardButton);
