const nominationStatus = {
  NONE: 0,
  DRAFT: 100,
  PENDING_REWRITE: 200,
  PENDING_USDH_APPROVAL: 300,
  PENDING_HR_REVIEW: 400,
  PENDING_JCAC_SIGNOFF: 500,
  PENDING_HR_ENDORSEMENT: 600,
  PENDING_EXEC_SIGNOFF: 700,
  PENDING_EXTERNAL_APPROVAL: 800,
  PENDING_ADDITIONAL_APPROVAL: 900,
  PENDING_MANAGEMENT_APPROVAL: 1000,
  PENDING_FINANCIAL_SIGNOFF: 1100,
  PENDING_PAYROLL_ACTION: 1200,
  PENDING_FINANCIAL_INFORMATION: 1300,
  PENDING_EFFECTIVE_DATE: 1400,
  PENDING_SEND_TO_OPS: 1500,
  PENDING_ANYTIME_APPROVER: 1600,
  PENDING_BUREAU_REVIEW: 1700,
  PENDING_BUREAU_COMMITTEE_VOTE: 1750,
  PENDING_BUREAU_COMMITTEE_CHAIR: 1800,
  BUREAU_APPROVED: 1850,
  BUREAU_DISAPPROVED: 1860,
  BUREAU_DOWNGRADED: 1870,
  PENDING_POST_REVIEW: 1900,
  COMPLETED: 2000,
  DISAPPROVED: 2100,
  DISAPPROVED_BY_SUPERVISOR: 2200,
  DISAPPROVED_BY_EXTERNAL: 2220,
  PENDING_POST_PROCESSING: 2300,
  DELETED: 2900,
  getName(status) {
    switch (status) {
      case this.DRAFT:
        return 'Draft';
      case this.PENDING_REWRITE:
        return 'Pending Rewrite';
      case this.PENDING_USDH_APPROVAL:
        return 'Pending USDH Approval';
      case this.PENDING_HR_REVIEW:
        return 'Pending HR Review';
      case this.PENDING_JCAC_SIGNOFF:
        return 'Pending JCAC Signoff';
      case this.PENDING_HR_ENDORSEMENT:
        return 'Pending HR Endorsement';
      case this.PENDING_EXEC_SIGNOFF:
        return 'Pending Exec Approval';
      case this.PENDING_EXTERNAL_APPROVAL:
        return 'Pending External Approval';
      case this.PENDING_ADDITIONAL_APPROVAL:
        return 'Pending Additional Approval';
      case this.PENDING_MANAGEMENT_APPROVAL:
        return 'Pending Management Approval';
      case this.PENDING_FINANCIAL_SIGNOFF:
        return 'Pending Financial Signoff';
      case this.PENDING_PAYROLL_ACTION:
        return 'Pending Payroll Action';
      case this.PENDING_FINANCIAL_INFORMATION:
        return 'Pending Financial Information';
      case this.PENDING_EFFECTIVE_DATE:
        return 'Pending Effective Date';
      case this.PENDING_SEND_TO_OPS:
        return 'Pending Send to OPS';
      case this.PENDING_ANYTIME_APPROVER:
        return 'Pending Anytime Approver';
      case this.PENDING_BUREAU_REVIEW:
        return 'Pending Bureau Review';
      case this.PENDING_BUREAU_COMMITTEE_VOTE:
        return 'Pending Bureau Committee Vote';
      case this.PENDING_BUREAU_COMMITTEE_CHAIR:
        return 'Pending Bureau Committee Sign Off';
      case this.BUREAU_APPROVED:
        return 'Bureau Approved';
      case this.BUREAU_DISAPPROVED:
        return 'Bureau Disapproved';
      case this.BUREAU_DOWNGRADED:
        return 'Bureau Downgraded';
      case this.PENDING_POST_REVIEW:
        return 'Pending Post Review';
      case this.COMPLETED:
        return 'Completed';
      case this.DISAPPROVED:
        return 'Disapproved';
      case this.DISAPPROVED_BY_SUPERVISOR:
        return 'Disapproved by Supervisor';
      case this.DISAPPROVED_BY_EXTERNAL:
        return 'Disapproved by External';
      case this.PENDING_POST_PROCESSING:
        return 'Pending Post Processing';
      case this.DELETED:
        return 'Deleted';
      default:
        return 'None';
    }
  },
};

export default nominationStatus;
